import React from 'react'
import SelectFilter from '../../atoms/select-filter/select-filter'
import Chip from '../../atoms/chip/chip'
import './FiltersProduct.scss'
import { useStaticQuery, graphql } from 'gatsby'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase';

const FiltersProduct = props => {
	const {
		onChangeCategory,
		onChangeLinea,
		onChangeUsuario,
		onChangeIncontinencia,
		onClearFilters,
		filterCategory = {},
		filterLinea = {},
		filterUsuario = {},
		filterIncontinencia = {},
	} = props

	const queryFilterProduct = useStaticQuery(graphql`
		query FILTERS_PRODUCT {
			allContentfulTenaSeccionProductos {
				nodes {
					node_locale
					filtroCategoria {
						titulo
						slug
						etiquetas {
							nombreDeEtiqueta
							etiquetasAdicionales {
								nombreDeEtiqueta
							}
						}
					}
					filtroDirigidoA {
						nombreDeEtiqueta
						etiquetasAdicionales {
							nombreDeEtiqueta
						}
					}
					filtroLinea {
						nombreDeEtiqueta
						etiquetasAdicionales {
							nombreDeEtiqueta
						}
					}
					filtroTipoDeIncontinencia {
						nombreDeEtiqueta
						etiquetasAdicionales {
							nombreDeEtiqueta
						}
					}
				}
			}
		}
	`)
	const filterInfo = queryFilterProduct.allContentfulTenaSeccionProductos.nodes.filter(
		e =>
			e.node_locale === process.env.LOCALE &&
			e.filtroCategoria !== null &&
			e.filtroDirigidoA !== null &&
			e.filtroLinea !== null &&
			e.filtroTipoDeIncontinencia !== null
	)[0]
	let filtroCategoria = filterInfo.filtroCategoria
	let filtroDirigidoA = filterInfo.filtroDirigidoA
	let filtroLinea = filterInfo.filtroLinea
	let filtroTipoDeIncontinencia = filterInfo.filtroTipoDeIncontinencia

	filtroCategoria.unshift({ titulo: 'Todos', slug: 'todos' })
	filtroDirigidoA.unshift({ nombreDeEtiqueta: 'Todos' })
	filtroLinea.unshift({ nombreDeEtiqueta: 'Todos' })
	filtroTipoDeIncontinencia.unshift({ nombreDeEtiqueta: 'Todos' })

	// filter uniques data
	const flagsCategoria = {}
	filtroCategoria = filtroCategoria.filter(entry => {
		if (flagsCategoria[entry.titulo]) {
			return false
		}
		flagsCategoria[entry.titulo] = true
		return true
	})

	const flagsDirigido = {}
	filtroDirigidoA = filtroDirigidoA.filter(entry => {
		if (flagsDirigido[entry.nombreDeEtiqueta]) {
			return false
		}
		flagsDirigido[entry.nombreDeEtiqueta] = true
		return true
	})

	const flagsLinea = {}
	filtroLinea = filtroLinea.filter(entry => {
		if (flagsLinea[entry.nombreDeEtiqueta]) {
			return false
		}
		flagsLinea[entry.nombreDeEtiqueta] = true
		return true
	})

	const flagsIncontinencia = {}
	filtroTipoDeIncontinencia = filtroTipoDeIncontinencia.filter(entry => {
		if (flagsIncontinencia[entry.nombreDeEtiqueta]) {
			return false
		}
		flagsIncontinencia[entry.nombreDeEtiqueta] = true
		return true
	})

	return (
		<div className='f-filters-product'>
			<h2 className='f-filters-product__title'>FILTRAR POR</h2>
			<div className='f-filters-filters-wrapper'>
				<SelectFilter title='Categoría…'>
					{filtroCategoria.map((filter, index) => (
						<Chip
							onClick={() => {
								onChangeCategory({
									key: filter.titulo,
									value: filter.titulo,
								})
							}}
							key={`f-filter-format-${index}`}
							isActive={Object.prototype.hasOwnProperty.call(
								filterCategory,
								filter.titulo
							)}
						>
							{filter.titulo}
						</Chip>
					))}
				</SelectFilter>
				<SelectFilter title='Línea…'>
					{filtroLinea.map((filter, index) => (
						<Chip
							onClick={() => {
								onChangeLinea({
									key: filter.nombreDeEtiqueta,
									value: filter.nombreDeEtiqueta,
								})
							}}
							key={`f-filter-interest-${index}`}
							isActive={Object.prototype.hasOwnProperty.call(
								filterLinea,
								filter.nombreDeEtiqueta
							)}
						>
							{filter.nombreDeEtiqueta}
						</Chip>
					))}
				</SelectFilter>
				<SelectFilter title='Usuario…'>
					{filtroDirigidoA.map((filter, index) => (
						<Chip
							onClick={() => {
								onChangeUsuario({
									key: filter.nombreDeEtiqueta,
									value: filter.nombreDeEtiqueta,
								})
							}}
							key={`f-filter-managed-${index}`}
							isActive={Object.prototype.hasOwnProperty.call(
								filterUsuario,
								filter.nombreDeEtiqueta
							)}
						>
							{filter.nombreDeEtiqueta}
						</Chip>
					))}
				</SelectFilter>
				<SelectFilter title='Tipo de incontinencia…'>
					{filtroTipoDeIncontinencia.map((filter, index) => (
						<Chip
							onClick={() => {
								onChangeIncontinencia({
									key: filter.nombreDeEtiqueta,
									value: filter.nombreDeEtiqueta,
								})
							}}
							key={`f-filter-content-${index}`}
							isActive={Object.prototype.hasOwnProperty.call(
								filterIncontinencia,
								filter.nombreDeEtiqueta
							)}
						>
							{filter.nombreDeEtiqueta}
						</Chip>
					))}
				</SelectFilter>
			</div>
			<div className='f-filters-filters-wrapper-mobile'>
				<SelectFilter title='Filtrar por…'>
					<div className='f-mobile-filter'>
						<p className='f-mobile-filter__title'>CATEGORÍA</p>
						<div className='f-mobile-filter-wrapper'>
							{filtroCategoria.map((filter, index) => (
								<Chip
									onClick={() => {
										onChangeCategory({
											key: filter.titulo,
											value: filter.titulo,
										})
									}}
									key={`f-filter-format-${index}`}
									isActive={Object.prototype.hasOwnProperty.call(
										filterCategory,
										filter.titulo
									)}
								>
									{filter.titulo}
								</Chip>
							))}
						</div>
					</div>

					<div className='f-mobile-filter'>
						<p className='f-mobile-filter__title'>LÍNEA</p>
						<div className='f-mobile-filter-wrapper'>
							{filtroLinea.map((filter, index) => (
								<Chip
									onClick={() => {
										onChangeLinea({
											key: filter.nombreDeEtiqueta,
											value: filter.nombreDeEtiqueta,
										})
									}}
									key={`f-filter-interest-${index}`}
									isActive={Object.prototype.hasOwnProperty.call(
										filterLinea,
										filter.nombreDeEtiqueta
									)}
								>
									{filter.nombreDeEtiqueta}
								</Chip>
							))}
						</div>
					</div>

					<div className='f-mobile-filter'>
						<p className='f-mobile-filter__title'>Usuario</p>
						<div className='f-mobile-filter-wrapper'>
							{filtroDirigidoA.map((filter, index) => (
								<Chip
									onClick={() => {
										onChangeUsuario({
											key: filter.nombreDeEtiqueta,
											value: filter.nombreDeEtiqueta,
										})
									}}
									key={`f-filter-managed-${index}`}
									isActive={Object.prototype.hasOwnProperty.call(
										filterUsuario,
										filter.nombreDeEtiqueta
									)}
								>
									{filter.nombreDeEtiqueta}
								</Chip>
							))}
						</div>
					</div>

					<div className='f-mobile-filter'>
						<p className='f-mobile-filter__title'>Tipo de incontinencia</p>
						<div className='f-mobile-filter-wrapper'>
							{filtroTipoDeIncontinencia.map((filter, index) => (
								<Chip
									onClick={() => {
										onChangeIncontinencia({
											key: filter.nombreDeEtiqueta,
											value: filter.nombreDeEtiqueta,
										})
									}}
									key={`f-filter-content-${index}`}
									isActive={Object.prototype.hasOwnProperty.call(
										filterIncontinencia,
										filter.nombreDeEtiqueta
									)}
								>
									{filter.nombreDeEtiqueta}
								</Chip>
							))}
						</div>
					</div>
				</SelectFilter>
			</div>

			<div className='f-filters-filters-selected'>
				<div className='f-filters-filters-selected-wrapper'>
					{Object.keys(filterCategory).length > 0 && (
						<div className='f-filters-filters-selected-type'>
							<p className='f-filters-filters-selected-type__title'>
								Categoría
							</p>
							<div>
								{Object.keys(filterCategory).map((key, index) => (
									<Chip
										key={`f-filter-${index}`}
										isCloseActive
										isActive
										onClick={() => {
											onChangeCategory({
												key,
												value: filterCategory[key],
											})
										}}
									>
										{filterCategory[key]}
									</Chip>
								))}
							</div>
						</div>
					)}
					{Object.keys(filterLinea).length > 0 && (
						<div className='f-filters-filters-selected-type'>
							<p className='f-filters-filters-selected-type__title'>Línea</p>
							<div>
								{Object.keys(filterLinea).map((key, index) => (
									<Chip
										key={`f-filter-${index}`}
										isCloseActive
										isActive
										onClick={() => {
											onChangeLinea({
												key,
												value: filterLinea[key],
											})
										}}
									>
										{filterLinea[key]}
									</Chip>
								))}
							</div>
						</div>
					)}
					{Object.keys(filterUsuario).length > 0 && (
						<div className='f-filters-filters-selected-type'>
							<p className='f-filters-filters-selected-type__title'>Usuario</p>
							<div>
								{Object.keys(filterUsuario).map((key, index) => (
									<Chip
										key={`f-filter-${index}`}
										isCloseActive
										isActive
										onClick={() => {
											onChangeUsuario({
												key,
												value: filterUsuario[key],
											})
										}}
									>
										{filterUsuario[key]}
									</Chip>
								))}
							</div>
						</div>
					)}
					{Object.keys(filterIncontinencia).length > 0 && (
						<div className='f-filters-filters-selected-type'>
							<p className='f-filters-filters-selected-type__title'>
								TIPO DE incontinencia
							</p>
							<div>
								{Object.keys(filterIncontinencia).map((key, index) => (
									<Chip
										key={`f-filter-${index}`}
										isCloseActive
										isActive
										onClick={() => {
											onChangeIncontinencia({
												key,
												value: filterIncontinencia[key],
											})
										}}
									>
										{filterIncontinencia[key]}
									</Chip>
								))}
							</div>
						</div>
					)}
				</div>
				{(Object.keys(filterCategory).length > 0 ||
					Object.keys(filterLinea).length > 0 ||
					Object.keys(filterUsuario).length > 0 ||
					Object.keys(filterIncontinencia).length > 0) && (
					<div className='f-filters-filters-button'>
						<button onClick={onClearFilters}>{convertText('LIMPIAR FILTROS')}</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default FiltersProduct
