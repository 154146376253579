import React, { useState } from 'react'
import ProductDetailSection from '../../../shared/components/molecules/productDetailSection/productDetailSection'
import DestinyFreeSample from '../destinyFreeSample/destiny-free-sample'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import './modal-free-sample.scss'

const ModalFreeSample = ({
	hideAction,
	product,
	labelMuestra,
	labelButtonMuestra,
	recipients,
	recipientS,
	addSample,
	lbtalla,
	samples,
	samplesSent,
	isFreeSamples,
}) => {
	const [size, setSize] = useState(null)
	const [desSample, setDesSample] = useState(null)

	const _addSample = () => {
		addSample({
			id: product.codigoDeProducto,
			img: product.imagenMuestraPrevisualizacion.fluid.src,
			name: product.nombreProducto,
			customCoverage: product.coberturaPersonalizada,
			destiny: desSample,
			categoria: product.categorias[0].titulo||'',
			variant: product.linea[0]? product.linea[0]?.nombreDeEtiqueta : undefined,
			drip_degree: product.tipoDeIncontinencia? product.tipoDeIncontinencia[0].nombreDeEtiqueta||'' : '',
			size,
		})
		hideAction()
	}

	return (
		<LayoutModal onClose={hideAction}>
			<div className='f-card-modal'>
				<div className='f-card-modal-head'>
					<span className='f-card-modal-head-icon' onClick={hideAction}>
						X
					</span>
				</div>
				<div className='f-card-modal-content'>
					<ProductDetailSection
					    product={product}
						codigoDeProducto={product.codigoDeProducto}
						isFreeSamples={true}
						idProduct={product.id}
						title={product.nombreProducto}
						descripcion={product.descripcionProducto}
						numeroGotas={product.numeroDeGotas}
						numeroGotasLlenas={product.numeroDeGotasLlenas}
						imagenProducto={
							isFreeSamples
								? product.imagenMuestraCompleta
								: product.imagenProductoCompleta
						}
						nivelIncontinencia={product.nivelIncontinencia}
						slug={product.slug.split('/')[2]}
						sizes={product.talla}
						selectSize={setSize}
						sizeSelected={size}
						lbtalla={lbtalla}
						samples={samples.filter(
							sample => sample.id === product.codigoDeProducto
						)}
						samplesSent={samplesSent}
						atributes={product.sellos}
					/>
					<DestinyFreeSample
						product={product}
						selectDestinySample={setDesSample}
						canAdd={
							desSample &&
							!samples.find(
								sample =>
									sample.id === product.codigoDeProducto && sample.size === size
							) &&
							(!product.talla || (product.talla && size))
						}
						handleAction={_addSample}
						labelButton={labelButtonMuestra}
						label={labelMuestra}
						recipients={recipients}
						recipientS={recipientS}
					/>
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalFreeSample
