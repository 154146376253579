import React, { useEffect } from 'react'
import './search-adviser.scss'
import Icon from './../Icon/Icon'
import AdviserService from '../../../services/adviser'
// import { localStorage, sessionStorage } from '../../../state/utils/storage';
import SessionService from '../../../services/session'
import { isAdviser } from '../../../state/utils/isAdviser'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useBrowserMode from '../../../hooks/useBrowserMode'
import Loader from '../Loader/Loader'

const SearchAdviser = props => {
	const { homeLink } = useHomeLinkMode()
	const { propSetValue, propOnclick } = props
	const { isBrowser } = useBrowserMode()
	const [inputValue, setInputValue] = React.useState('')
	const [message, setMessage] = React.useState('')
	const [loader, setLoader] = React.useState(false)
	const [nameUser, setNameUser] = React.useState(null)

	useEffect(() => {
		setNameUser(SessionService.getUserData())
		props.setUserData && props.setUserData(SessionService.getUserData())
	}, [])

	const handleCloseSession = () => {
		localStorage.clear()
		sessionStorage.clear()
		window.location.href = homeLink
	}

	const searchUser = () => {
		setLoader(true)
		props.setLoader && props.setLoader(true)
		AdviserService.searchUser(
			inputValue,
			res => {
				if (res.status === 200) {
					setMessage(res.data.developerMessage)
					if (res.data.status === 200) {
						sessionStorage.setItem('userData', JSON.stringify(res.data.data))
						localStorage.setItem('userData', JSON.stringify(res.data.data))
						setNameUser(res.data.data)
						props.setUserData && props.setUserData(res.data.data)
						if (!propSetValue) {
							window.location.reload()
						}
					}
				} else {
					console.log(res)
				}
				setLoader(false)
				props.setLoader && props.setLoader(false)
			},
			error => {
				console.log(error)
			}
		)
	}

	return (
		<>
			{isBrowser
				? localStorage.getItem('tokenData') ||
				  sessionStorage.getItem('tokenData')
					? isAdviser(SessionService.getIdTokenData()) && (
							<>
								<div className='input-container-position'>
									<p>
										Asesor:{' '}
										<b>
											{sessionStorage.getItem('userNameAdviser') &&
												sessionStorage.getItem('userNameAdviser')}
										</b>{' '}
										<u onClick={handleCloseSession}>Cerrar Sesión</u>
									</p>
								</div>
								<div className='input-container-position'>
									<div className='input-container'>
										<div
											className='input-container-backHome'
											onClick={() =>
												(window.location.href = homeLink + 'callcenter')
											}
										>
											{' '}
											<span className='icon-home'></span>
										</div>
										<input
											value={inputValue}
											onChange={e => {
												setInputValue(e.target.value)
												propSetValue && propSetValue(e.target.value)
											}}
											className='input-search'
											placeholder='Buscar usuario por correo o cédula'
										/>
										<button
											type='button'
											className='f-button-search'
											onClick={() => {
												searchUser()
												propOnclick && propOnclick()
											}}
										>
											<Icon icon='icon-search' size='1-5' tagType='i' />
										</button>
									</div>
									{props.isCallHome !== true && (
										<div className='input-container-user'>
											{nameUser && (
												<p>
													Usuario buscado:{' '}
													<span>
														{nameUser.name} {nameUser.lastName} -{' '}
														{nameUser.segment} - {nameUser.state}
													</span>
												</p>
											)}
											<p>{message}</p>
											{loader && <Loader class='lonchmara' />}
										</div>
									)}
								</div>
							</>
					  )
					: null
				: null}
		</>
	)
}

export default SearchAdviser
