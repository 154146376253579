import React from 'react'
import Img from 'gatsby-image'
import './modal-muestras-gratis.scss'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import useMobileMode from '../../../hooks/useMobileMode'

const ModalMuestrasGratis = props => {
	const { isMobile } = useMobileMode()
	return (
		<LayoutModal>
			<div className='f-modal-samples-wrapper'>
				<span className='f-modal-samples-close' onClick={props.closeAction}>
					X
				</span>
				<div className='f-modal-samples-body'>
						<Img
							className='f-img-promo'
							fluid={
								props.content[isMobile ? 1 : 0].fluid
							}
							alt={props.content.description}
						/>
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalMuestrasGratis