import { useState } from 'react'
import useModalGeneric from './useModalGeneric'

export default function useModalFreeSample() {
	const [product, setProduct] = useState(null)
	const [isModal, showModal, hideModal] = useModalGeneric(false)

	const selectProduct = value => {
		setProduct(value)
		showModal()
	}

	const unSelectProduct = () => {
		setProduct(null)
		hideModal()
	}

	return [isModal, product, selectProduct, unSelectProduct]
}
