import * as constants from '../state/constants/apiConstants'
import { axiosInstance } from './axiosInstance'
import SessionService from './session'

const AdviserService = {
	searchUser: async (data, success, error) => {
		try {
			success(
				await axiosInstance.get(
					constants.SERVER_URL +
						'account/api/v1/call-center/user?userName=' +
						data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
	updateProfile: async (data, success, error) => {
		try {
			success(
				await axiosInstance.put(
					constants.SERVER_URL + 'account/api/v1/call-center/user',
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
	sendSamples: async (samples, image, success, error) => {
		try {
			const data = {
				email: SessionService.getUserData().email,
				listProductDto: samples,
				image,
			}
			success(
				await axiosInstance.post(
					constants.SERVER_URL + 'samples/api/v1/call-center/sample-request',
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
	firstRegister: async (data, success, error) => {
		try {
			success(
				await axiosInstance.post(
					constants.SERVER_URL + 'register/api/v1/call-center/register',
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
	updateRoles: async (data, success, error) => {
		try {
			success(
				await axiosInstance.put(
					constants.SERVER_URL + 'register/api/v1/call-center/user',
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
	registerUser: async (data, success, error) => {
		try {
			success(
				await axiosInstance.put(
					constants.SERVER_URL + 'register/api/v1/call-center/register',
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
	myPoints: async (success, error) => {
		try {
			const data = SessionService.getUserData()
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			}
			const endpoint =
				constants.SERVER_URL +
				`accumulation/api/v1/call-center/accumulation-detail/${data.email}`

			success(await axiosInstance.get(endpoint, config))
		} catch (_error) {
			error(_error)
		}
	},
	myRedemptions: async (success, error) => {
		try {
			const data = SessionService.getUserData()
			const endpoint =
				constants.SERVER_URL +
				`redemption/api/v1/call-center/redemptions/${data.email}`
			// is valid token
			success(
				await axiosInstance.get(endpoint, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData(),
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},
	getMyOrders: async (success, error) => {
		try {
			const response = await axiosInstance.get(
				constants.SERVER_URL + 'samples/api/v1/call-center/orders',
				{
					params: {
						email: SessionService.getUserData().email,
					},
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData(),
					},
				}
			)
			success(response)
		} catch (_error) {
			error(_error)
		}
	},

	// PQR
	sendPqrs: async (data, success, error) => {
		try {
			success(
				await axiosInstance.post(
					constants.SERVER_URL + 'pqrs/api/v1/claim',
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: SessionService.getIdTokenData(),
						},
					}
				)
			)
		} catch (_error) {
			error(_error)
		}
	},
}

export default AdviserService
