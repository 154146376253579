import { graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import BannerAdvice from '../components/molecules/bannerAdvice/banner-advice'
import ModalAlert from '../components/molecules/modalAlert/modalAlert'
import ModalCoverage from '../components/molecules/modalCoverage/modalCoverage'
import ModalFreeSample from '../components/molecules/modalFreeSample/modal-free-sample'
import ModalSamplesAlert from '../components/molecules/modalSamplesAlert/modalSamplesAlert'
import FiltersProduct from '../components/molecules/FiltersProduct/FiltersProduct'
import ProductsSections from '../shared/components/molecules/productsSections/productsSections'
import ShoppingCar from '../components/molecules/shoppingCar/shopping-car'
import SearchAdviser from '../shared/components/atoms/search-adviser/search-adviser'
import ModalAuth from '../shared/components/molecules/modalAuth/modal-auth'
import ModalMuestrasGratis from '../shared/components/molecules/modal-muestras-gratis/modal-muestras-gratis'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useCoverageState from '../shared/hooks/useCoverageState'
import useCrudFreeSample from '../shared/hooks/useCrudFreeSample'
import useHomeLinkMode from '../shared/hooks/useHomeLinkMode'
import useLocaleMode from '../shared/hooks/useLocaleMode'
import useMobileMode from '../shared/hooks/useMobileMode'
import useModalAuth from '../shared/hooks/useModalAuth'
import useModalFreeSample from '../shared/hooks/useModalFreeSample'
import useModalGeneric from '../shared/hooks/useModalGeneric'
import SessionService from '../shared/services/session'
import useSessionState from '../shared/hooks/useSessionState'
import { replaceCategory } from '../shared/state/utils/utilities-product'

const HomeSectionFreeSample = props => {
	const [filteredProducts, setFilteredProducts] = useState(
		props.pageContext.productos.producto
	)
	const [filterCategory, setFilterCategory] = useState({})
	const [filterLinea, setFilterLinea] = useState({})
	const [filterUsuario, setFilterUsuario] = useState({})
	const [filtroTipoDeIncontinencia, setFilterTipoDeIncontinencia] = useState({})

	const { isMobile, currentPage } = useMobileMode()
	const [
		isModalFreeSample,
		product,
		selectProduct,
		unSelectProduct,
	] = useModalFreeSample()
	const [isModalAuth, validateSamples, hideModalAuth] = useModalAuth()
	const [showingModalAlert, showModalAlert, hideModalAlert] = useModalGeneric(
		false
	)
	const [samples, addSample, removeSample, response] = useCrudFreeSample()
	const { isLoggedUser } = useSessionState()
	// const context = props.data.allContentfulTenaMuestraGratis.nodes.filter(node => node.slug === props.pageContext.slug)[0]
	const context = props.pageContext
	const samplesSent = response ? response.status === 200 && response.data : []
	const { locale } = useLocaleMode()
	const [showingModalCoverage, showModalCoverage] = useModalGeneric(false)
	const [isModalPromosVisible, setModalPromosVisibility] = useState(false)
	const [isModalCustomCoverage, showModalCustomCoverage] = useModalGeneric(
		false
	)
	const [
		showingModalSamplesAlert,
		showModalSamplesAlert,
		hideModalSamplesAlert,
	] = useModalGeneric(false)
	const citysInit = props.data.allContentfulTenaPaises.nodes.filter(node =>
		locale.includes(node.codigoDeDosLetras)
	)[0]
	const citys =
		citysInit &&
		(locale === 'es-PR' ? citysInit.departamentos : citysInit.ciudades)
	const [
		isCoverage,
		validateCoverage,
		isAddress,
		validateAddress,
	] = useCoverageState(citys)
	console.log('====================================')
	console.log(isCoverage)
	console.log('====================================')
	const { homeLink } = useHomeLinkMode()
	const samplesLength = Number(
		props.data.allContentfulTenaHome.nodes[0].numeroDeMuestrasDiarias
	)
	const verifySent = (_idProdcut, _sizes, _samplesSent) => {
		if (_sizes) {
			const filter =
				_samplesSent &&
				_samplesSent.filter(
					sample => _sizes.includes(sample.size) && _idProdcut === sample.id
				)
			return filter && filter.length === _sizes.length
		}
		return !!(
			_samplesSent && _samplesSent.find(sample => _idProdcut === sample.id)
		)
	}
	React.useEffect(() => {
		if (!props.data.allContentfulTenaHome.nodes[0].tieneMuestras) {
			window.location.href = homeLink
		} else {
			const isValidateAddress = validateAddress()
			if (validateCoverage() === false || isValidateAddress === false) {
				showModalCoverage()
			}
		}
	})
	React.useEffect(() => {
		const isLogged = SessionService.getUserData()
		if (
			response &&
			response.status === 400 &&
			response.errorCode === 'DEM068'
		) {
			showModalSamplesAlert()
		} else if (response && response.status === 200 && response.data) {
			handleValidateParam(response.data)
		} else if (response === undefined && isLogged === null) {
			handleValidateParam()
		}
	}, [response])

	const handleValidateParam = _responseData => {
		const search = window.location.search
		const params = new URLSearchParams(search)
		const productParam = params.get('p')
		if (productParam !== null && productParam !== '') {
			const filteredProduct = context.productos.producto.filter(
				product => product.codigoDeProducto === productParam
			)
			if (_responseData && validateCoverage() === true) {
				if (
					filteredProduct &&
					samples.length === 0 &&
					!verifySent(
						filteredProduct[0].codigoDeProducto,
						filteredProduct[0].talla,
						_responseData
					)
				) {
					selectProduct(filteredProduct[0])
				}
			} else {
				if (filteredProduct && samples.length === 0) {
					selectProduct(filteredProduct[0])
				}
			}
		}
	}
	const filterProductsCategory = (products, filterCategory) => {
		const tempProductsCategory = []
		products.filter(
			e =>
				e.producto.categorias &&
				e.producto.categorias.map(item => {
					if (
						Object.prototype.hasOwnProperty.call(filterCategory, item.titulo)
					) {
						tempProductsCategory.push(e)
					}
					return null
				})
		)
		return tempProductsCategory
	}

	const filterProductsLinea = (products, filterLinea) => {
		const tempProductsLinea = []
		products.filter(
			e =>
				e.producto.linea &&
				e.producto.linea.map(item => {
					if (
						Object.prototype.hasOwnProperty.call(
							filterLinea,
							item.nombreDeEtiqueta
						)
					) {
						tempProductsLinea.push(e)
					}
					return null
				})
		)
		return tempProductsLinea
	}

	const filterProductsDirigido = (products, filterUsuario) => {
		const tempProductsDirigido = []
		products.filter(
			e =>
				e.producto.dirigidoA &&
				e.producto.dirigidoA.map(item => {
					Object.prototype.hasOwnProperty.call(
						filterUsuario,
						item.nombreDeEtiqueta
					)
					if (
						Object.prototype.hasOwnProperty.call(
							filterUsuario,
							item.nombreDeEtiqueta
						)
					) {
						tempProductsDirigido.push(e)
					}
					return null
				})
		)
		return tempProductsDirigido
	}
	const filterProductsIncontinencia = (products, filtroTipoDeIncontinencia) => {
		const tempProductsIncontinencia = []
		products.filter(
			e =>
				e.producto.tipoDeIncontinencia &&
				e.producto.tipoDeIncontinencia.map(item => {
					Object.prototype.hasOwnProperty.call(
						filtroTipoDeIncontinencia,
						item.nombreDeEtiqueta
					)
					if (
						Object.prototype.hasOwnProperty.call(
							filtroTipoDeIncontinencia,
							item.nombreDeEtiqueta
						)
					) {
						tempProductsIncontinencia.push(e)
					}
					return null
				})
		)
		return tempProductsIncontinencia
	}

	useEffect(() => {
		let allProducts = context.productos
		if (
			Object.keys(filterCategory).length > 0 &&
			!Object.prototype.hasOwnProperty.call(filterCategory, 'Todos')
		) {
			allProducts = filterProductsCategory(allProducts, filterCategory)
		}
		if (
			Object.keys(filterLinea).length > 0 &&
			!Object.prototype.hasOwnProperty.call(filterLinea, 'Todos')
		) {
			allProducts = filterProductsLinea(allProducts, filterLinea)
		}
		if (
			Object.keys(filterUsuario).length > 0 &&
			!Object.prototype.hasOwnProperty.call(filterUsuario, 'Todos')
		) {
			allProducts = filterProductsDirigido(allProducts, filterUsuario)
		}
		if (
			Object.keys(filtroTipoDeIncontinencia).length > 0 &&
			!Object.prototype.hasOwnProperty.call(filtroTipoDeIncontinencia, 'Todos')
		) {
			allProducts = filterProductsIncontinencia(
				allProducts,
				filtroTipoDeIncontinencia
			)
		}
		setFilteredProducts(allProducts)
	}, [filterCategory, filterLinea, filterUsuario, filtroTipoDeIncontinencia])

	const handleChangeProperty = (
		filterValues,
		setter,
		value,
		cleanAll = false
	) => {
		if (!Object.prototype.hasOwnProperty.call(filterValues, value.key)) {
			setter(
				filterValues
					? { ...filterValues, [`${value.key}`]: value.value }
					: { [`${value.key}`]: value.value }
			)
		} else {
			setter(state => {
				const clonendState = JSON.parse(JSON.stringify(state))
				delete clonendState[value.key]
				return clonendState
			})
		}
		if (cleanAll) {
			Object.keys(filterCategory).forEach(valueCategory => {
				setter(state => {
					const clonendState = JSON.parse(JSON.stringify(state))
					delete clonendState[valueCategory]
					return clonendState
				})
			})
		}
	}

	const actionSelectProduct = product => {
		const haveCustomCoverage =
			product.coberturaPersonalizada &&
			product.coberturaPersonalizada !== null &&
			product.coberturaPersonalizada?.length >= 1

		if (samples.length < samplesLength) {
			if (
				isLoggedUser &&
				haveCustomCoverage &&
				!validateCoverage(product.coberturaPersonalizada)
			) {
				showModalCustomCoverage()
			} else {
				const productSlugWithOutCategory = replaceCategory(product.slug || '')
				const productUrl = `/${context.slug}/${productSlugWithOutCategory}`
				window.location.href = productUrl
			}
		} else {
			showModalAlert()
		}
	}

	useEffect(() => {
		
		const modalPromos = sessionStorage.getItem('modalPromosSamples')
		if (modalPromos === null) {
			setModalPromosVisibility(true)
			sessionStorage.setItem('modalPromosSamples', 'showed')
		}
	}, [])


	useEffect(() => {
		localStorage.setItem('previousPageRegister', 'fromFreeSample')
	}, [])

	return (
		<Layout title={context.metaTitulo} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={context.metaTitulo}
				description={context.metaDescripcion.metaDescripcion}
				lang={locale}
			/>
			<article style={{ paddingBottom: '0', overflow: 'hidden' }}>
				<div>
					<SearchAdviser />
				</div>
				{showingModalCoverage && isAddress && (
					<ModalCoverage
						actionAlert={() =>
							(window.location.href = homeLink + 'mi-cuenta/datos-personales')
						}
						description='No tenemos cobertura en tu zona para que recibas este producto. Debes ingresar una dirección donde nuestra marca tenga cobertura.'
						buttonLabel='ACEPTAR'
					/>
				)}
				{showingModalCoverage && !isAddress && (
					<ModalCoverage
						description='Para solicitar una muestra gratis debes tener una dirección asociada, por favor agrega una dirección válida.'
						actionAlert={() =>
							(window.location.href = homeLink + 'mi-cuenta/datos-personales')
						}
					/>
				)}
				{showingModalSamplesAlert &&
					!showingModalCoverage &&
					!isModalCustomCoverage && (
						<ModalSamplesAlert
							hideAction={hideModalSamplesAlert}
							actionAlert={() =>
								(window.location.href = homeLink + 'mi-cuenta/datos-personales')
							}
						/>
					)}
				{context && (
					<>
						<BannerAdvice
							json={context.banner && context.banner.json}
							title={context.titulo}
							infoVideo ={props.data.allContentfulTenaPaises.nodes.filter(node =>
								locale.includes(node.codigoDeDosLetras)
							)[0].videoMuestras}
						/>
						{/* <TabProducts slug={context.slug} tabs={context.menu} /> */}
						<FiltersProduct
							onChangeCategory={value => {
								handleChangeProperty(
									filterCategory,
									setFilterCategory,
									value,
									true
								)
							}}
							onChangeLinea={value => {
								handleChangeProperty(filterLinea, setFilterLinea, value)
							}}
							onChangeUsuario={value => {
								handleChangeProperty(filterUsuario, setFilterUsuario, value)
							}}
							onChangeIncontinencia={value => {
								handleChangeProperty(
									filtroTipoDeIncontinencia,
									setFilterTipoDeIncontinencia,
									value
								)
							}}
							onClearFilters={() => {
								setFilterCategory({})
								setFilterLinea({})
								setFilterUsuario({})
								setFilterTipoDeIncontinencia({})
							}}
							filterCategory={filterCategory}
							filterLinea={filterLinea}
							filterUsuario={filterUsuario}
							filterIncontinencia={filtroTipoDeIncontinencia}
						/>
						<ProductsSections
							isFreeSamples={true}
							actionSection={actionSelectProduct}
							modeAction={[context.lblAgregar, context.lblAgregado]}
							title={'*'}
							isCurved={true}
							noCenter={true}
							slug={context.slug + '/'}
							isMobile={isMobile}
							productos={filteredProducts || context.productos}
							samples={samples}
							samplesSent={samplesSent}
							isLoggedUser={isLoggedUser}
							leafletContent={context.queryRegister.nodes[0]}
							showLeafletCard={true}
						/>
						{isModalPromosVisible && props.pageContext.tieneModal && (
							<ModalMuestrasGratis
								content={props.pageContext.imagenModal}
								closeAction={() => {
									setModalPromosVisibility(false)
								}}
							/>
						)}
						{!showingModalCoverage &&
							!isModalCustomCoverage &&
							samples &&
							samples.length > 0 && (
								<ShoppingCar
									removeSample={removeSample}
									samples={samples}
									jsonProductSelec={context.productosSeleccionados.json}
									actionCar={validateSamples}
									labelButton={context.lblSolicitarMuestra}
								/>
							)}
						{isModalFreeSample && (
							<ModalFreeSample
								isFreeSamples={true}
								slug={context.slug}
								product={product}
								labelButtonMuestra={context.lblAgregar}
								labelMuestra={context.laMuestraEsPara}
								recipientS={context.destinatariosDeMuestra}
								hideAction={unSelectProduct}
								addSample={addSample}
								lbtalla={context.tallaNecesitada}
								samples={samples}
								samplesSent={samplesSent}
								showModalCoverage={showModalCoverage}
							/>
						)}
						{isModalAuth && (
							<ModalAuth
								hideAction={hideModalAuth}
								validateCoverage={validateCoverage}
								showModalCoverage={showModalCoverage}
							/>
						)}
						{showingModalAlert && (
							<ModalAlert
								hideAction={hideModalAlert}
								actionAlert={validateSamples}
							/>
						)}
					</>
				)}
			</article>
		</Layout>
	)
}

export default HomeSectionFreeSample

export const query = graphql`
	query GET_CONTENT_FREE_SAMPLES($language: String!) {
		allContentfulTenaPaises(filter: { node_locale: { eq: "es-CO" } }) {
			nodes {
				codigoDeDosLetras
				titulo
				node_locale
				codigoDelPais
				ciudades {
					codigo
					id
					nombre
					codigoDepartamento
					coverage
				}
				departamentos {
					codigo
					nombre
					coverage
				}
				videoMuestras
			}
		}
		allContentfulTenaHome(filter: { node_locale: { eq: $language } }) {
			nodes {
				node_locale
				tieneTiendas
				tieneMuestras
				numeroDeMuestrasDiarias
			}
		}
	}
`
