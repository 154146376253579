import React, { useRef, useEffect, useState } from 'react'
import './select-filter.scss'

const SelectFilter = ({ title = '', children }) => {
	const [isOpen, setIsOpen] = useState()

	/**
	 * Hook that alerts clicks outside of the passed ref
	 */
	const useOutsideAlerter = ref => {
		useEffect(() => {
			/**
			 * Change state if clicked on outside of element
			 */

			const handleClickOutside = event => {
				if (!ref.current.contains(event.target)) {
					setIsOpen(false)
				}
			}

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside)
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}, [ref])
	}

	const wrapperRef = useRef(null)
	useOutsideAlerter(wrapperRef)

	return (
		<div
			ref={wrapperRef}
			className={`f-select-filter ${isOpen ? 'is-open' : ''}`}
			onClick={e => {
				e.preventDefault()
				e.stopPropagation()
				setIsOpen(!isOpen)
			}}
		>
			<p className='f-select-filter__label'>{title}</p>
			<i className='icon-chevron-up'></i>
			<div className='f-select-filter-content'>{children}</div>
		</div>
	)
}

export default SelectFilter
