import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import React from 'react'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import './banner-advice.scss'

const BannerAdvice = props => {
	const locale = process.env.LOCALE || 'es-CO'
	const { isBrowser } = useBrowserMode()
	const {infoVideo} = props

	const options = {
		renderNode: {
			[BLOCKS.HEADING_1]: (node, next) => (
				<h1 className='f-free-sample-title'>{next}</h1>
			),
			[BLOCKS.PARAGRAPH]: (node, next) => (
				<p className='f-free-sample-description'>{next}</p>
			),
		},
	}

	return (
		<section className='f-free-sample'>
			<div className='f-free-sample-container'>
				{isBrowser && (locale === 'es-CO' || locale === 'es-EC') ?(
					<iframe
					className='f-free-sample-video'
					frameBorder='0'
					sandbox='allow-popups allow-same-origin allow-scripts'
					scrolling='no'
					src={
						infoVideo.includes('youtube')
							? infoVideo.replace('watch?v=', 'embed/')
							: infoVideo.includes('youtu.be')
								? infoVideo.replace('.be/', 'be.com/embed/')
								: infoVideo
					}
					allowFullScreen
				/>
				):(
					<img
						className='f-free-sample-image'
						src={
							props.json.content[0].data?.target.fields.file[locale]
								? props.json.content[0].data?.target.fields.file[locale].url
								: props.json.content[0].data?.target.fields.file['es-CO'].url
						}
						alt={
							props.json.content[0].data?.target.fields.description[locale]
								? props.json.content[0].data?.target.fields.description[locale]
								: props.json.content[0].data?.target.fields.description['es-CO']
						}
					/>
				)}
				<div className="f-free-sample-content">
					{documentToReactComponents(props.json, options)}
				</div>
			</div>
			<div className='f-free-sample-footer'>
				{props.title || 'Selecciona el producto que quieres solicitar para tu muestra gratis.'}
			</div>
		</section>
	)
}

export default BannerAdvice
