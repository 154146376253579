import React from 'react'
import './chip.scss'
const Chip = ({ children, onClick, isActive, isCloseActive }) => {
	return (
		<button
			onClick={onClick}
			className={`f-chip ${isActive ? 'is-active' : ''}`}
		>
			{children}
			{isCloseActive && <i className='icon-times'></i>}
		</button>
	)
}

export default Chip
